<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card title="Statistique par commercial">
          <b-row>
            <b-col
              v-if="commerciaux.length"
              md="4"
            >
              <b-form-group
                label="Commerciaux"
                label-for="commerciaux"
              >
                <v-select
                  v-model="commercial"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="full_name"
                  :options="commerciaux"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Date de début"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date1"
                  v-model="dateDebutStat"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Date de fin"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date2"
                  v-model="dateFinStat"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div style="text-align: center">
                <b-overlay
                  :show="show"
                  rounded="sm"
                  spinner-variant="primary"
                  spinner-small
                  class="d-inline-block"
                >
                  <b-button
                    id="toggle-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.distributeur-modal-prevent-closing
                    variant="outline-primary"
                    class="btn_export"
                    @click="getStats"
                  >
                    <feather-icon icon="SearchIcon" />
                    Filtrer
                  </b-button>
                </b-overlay>
              </div>

            </b-col>
          </b-row>
          <hr>
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche
                </label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
            <!-- primary -->
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Exporter"
              variant="primary"
              class="ml-2 btn_export"
            >
              <b-dropdown-item @click="exportCSVS">
                CSV
              </b-dropdown-item>
              <b-dropdown-item @click="exportExcel">
                MS-Excel
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <vue-good-table
            id="list"
            ref="myTable"
            :columns="columns"
            :rows="stateData"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Status -->
              <span v-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  :to="{ name: 'tourners-visite-details', params: { id: props.row.id } }"
                >
                  Voir
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="ml-1"
                  @click="handleDelete(props.row.id)"
                >
                  Supprimer
                </b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage de 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> sur {{ props.total }} élements </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
          <hr>
          <h2>Nombre de visites effectuées par jour.</h2>

          <VisitesCommercialJourChart
            :key="componentVCJCKey"
            :data="visitesCommercialJourChartData"
          />
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Taux de disponibilité par commercial">
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Date de début"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date1"
                  v-model="dateDebut"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date de fin"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date2"
                  v-model="dateFin"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div style="text-align: center">
                <b-overlay
                  :show="show1"
                  rounded="sm"
                  spinner-variant="primary"
                  spinner-small
                  class="d-inline-block"
                >
                  <b-button
                    id="toggle-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.distributeur-modal-prevent-closing
                    variant="outline-primary"
                    class="btn_export"
                    @click="getAvailabilityRatesData"
                  >
                    <feather-icon icon="SearchIcon" />
                    Filtrer
                  </b-button>
                </b-overlay>
              </div>

            </b-col>
          </b-row>
          <hr>
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche
                </label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
            <!-- primary -->
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Exporter"
              variant="primary"
              class="ml-2 btn_export"
            >
              <b-dropdown-item @click="exportCSVS2">
                CSV
              </b-dropdown-item>
              <b-dropdown-item @click="exportExcel">
                MS-Excel
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <vue-good-table
            id="list"
            ref="myTable"
            :columns="columns2"
            :rows="disponibilities_data"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength2
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: afridia_availability_rate_strict -->
              <span
                v-if="props.column.field === 'afridia_availability_rate_strict'"
                style="color: green"
              >
                [{{ props.row.afridia_products_available_sum }}]
              </span>

              <!-- Column: afridia_availability_rate -->
              <span
                v-if="props.column.field === 'afridia_availability_rate'"
                style="color: green"
              >
                [{{ props.row.afridia_products_available_per_visit }}]
              </span>
              <!-- Column: concurrent_availability_rate_strict -->
              <span
                v-if="props.column.field === 'concurrent_availability_rate_strict'"
                style="color: red"
              >
                [{{ props.row.concurrent_products_available_sum }}]
              </span>
              <span
                v-if="props.column.field === 'concurrent_availability_rate'"
                style="color: red"
              >
                [{{ props.row.concurrent_products_available_per_visit }}]
              </span>

              <!-- Column: actions_done_rate_strict -->
              <span
                v-if="props.column.field === 'actions_done_rate_strict'"
                style="color: green"
              >
                [{{ props.row.actions_done_sum }}]
              </span>

              <!-- Column: Status -->
              <span v-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  :to="{ name: 'tourners-visite-details', params: { id: props.row.id } }"
                >
                  Voir
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="ml-1"
                  @click="handleDelete(props.row.id)"
                >
                  Supprimer
                </b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage de 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength2"
                    :options="['3','5','10','20']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> sur {{ props.total }} élements </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength2"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
          <hr>
          <StackedColumnChart
            :key="componentKey"
            :data="chartDataStackedColumnChart"
            category-field="full_name"
            :series="series"
            x-axis-title="Commercial"
            y-axis-title="Taux (%)"
          />
        </b-card>
      </b-col>
      <!--  <b-col md="12">
         <b-card title="Visites par commercial et jour">
           <b-row>
             <b-col
               v-if="commerciaux.length"
               md="4"
             >
               <b-form-group
                 label="Commerciaux"
                 label-for="commerciaux"
               >
                 <v-select
                   v-model="commercial"
                   :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                   label="full_name"
                   :options="commerciaux"
                 />
               </b-form-group>
             </b-col>
             <b-col md="4">
               <b-form-group
                 label="Date de début"
                 label-for="nom-input"
               >

                 <b-form-input
                   id="nom-input"
                   ref="date1"
                   v-model="dateDebutVisite"
                   type="date"
                   required
                 />
               </b-form-group>
             </b-col>
             <b-col md="4">
               <b-form-group
                 label="Date de fin"
                 label-for="nom-input"
               >

                 <b-form-input
                   id="nom-input"
                   ref="date2"
                   v-model="dateFinVisite"
                   type="date"
                   required
                 />
               </b-form-group>
             </b-col>
             <b-col md="12">
               <div style="text-align: center">
                 <b-button
                   id="toggle-btn"
                   v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                   v-b-modal.distributeur-modal-prevent-closing
                   variant="outline-primary"
                   class="btn_export ml-2"
                   @click="getVisitesCommercialJour"
                 >
                   <feather-icon icon="SearchIcon" />
                   Filtrer
                 </b-button>
               </div>

             </b-col>
           </b-row>
           <hr>
           <hr>
           <VisitesCommercialJourChart
             :key="componentVCJCKey"
             :data="visitesCommercialJourChartData"
           />

         </b-card>
       </b-col> -->
      <b-col md="12">
        <b-card title="Nombre de Visites par commercial">
          <b-row>
            <b-col
              v-if="regions.length"
              md="4"
            >
              <b-form-group
                label="Regions"
                label-for="regions"
              >
                <v-select
                  v-model="region"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nom"
                  :options="regions"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Date de début"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date1"
                  v-model="dateDebutVisite"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Date de fin"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date2"
                  v-model="dateFinVisite"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div style="text-align: center">
                <b-overlay
                  :show="show2"
                  rounded="sm"
                  spinner-variant="primary"
                  spinner-small
                  class="d-inline-block"
                >
                  <b-button
                    id="toggle-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn_export"
                    @click="getVisitesCommercial"
                  >
                    <feather-icon icon="SearchIcon" />
                    Filtrer
                  </b-button>
                </b-overlay>
              </div>

            </b-col>
          </b-row>
          <hr>
          <hr>
          <!--          <VisitesCommercialJourChart-->
          <!--            :key="componentVCJCKey"-->
          <!--            :data="visitesCommercialJourChartData"-->
          <!--          />-->
          <SimpleColumnChartV
            v-if="visitesCommercials.length"
            :key="componentSimplieColumnChartKey"
            :chart-data="visitesCommercials"
            :category-name="'nom_commercial'"
            :series-name="'nombre_visite'"
            :value-y-name="'nombre_visite'"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BBadge,
  BButton, BCard, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { API_URL } from '@/helpers/global-scops'
import StackedColumnChart from '@/views/m-components/charts/StackedColumnChart.vue'
import VisitesCommercialJourChart from '@/views/m-components/dataAnalyseCharts/VisitesCommercialJourChart.vue'
import vSelect from 'vue-select'
import SimpleColumnChartV from '@/views/m-components/charts/SimpleColumnChartV.vue'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import { generateConcatenatedWord } from '@/helpers/methodes'
import * as XLSX from 'xlsx'

export default {
  name: 'MainVue',
  components: {
    BOverlay,
    BDropdownItem,
    BDropdown,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BBadge,
    SimpleColumnChartV,
    vSelect,
    VisitesCommercialJourChart,
    StackedColumnChart,
    BFormInput,
    BButton,
    BFormGroup,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      show1: false,
      show2: false,
      componentSimplieColumnChartKey: 0,
      pageLength: 10,
      pageLength2: 20,
      searchTerm: '',
      columns: [
        {
          label: 'Volume des commandes distributeurs',
          field: 'volume_commandes_distributeurs',
          filterable: true,
        },
        {
          label: 'Visites réalisées',
          field: 'nombre_visites_realisees',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Disponibilité des produits Afridia',
          field: 'disponibilite_afridia',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Disponibilité d\'au moins un produit Afridia',
          field: 'taux_disponibilite_jus_afridia',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Disponibilité concurrence',
          field: 'disponibilite_concurrence',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Retour DLUO',
          field: 'retour_dluo',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Retour PHN',
          field: 'retour_phn',
          type: 'number',
          filterable: true,
        },
      ],
      columns2: [
        {
          label: 'Commercial',
          field: 'full_name',
          filterable: true,
        },
        {
          label: 'Visites réalisées',
          field: 'total_visits',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Disponibilité des produits Afridia',
          field: 'afridia_availability_rate_strict',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Disponibilité d\'au moins un produit Afridia',
          field: 'afridia_availability_rate',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Disponibilité concurrence',
          field: 'concurrent_availability_rate_strict',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Disponibilité d\'au moins un produit concurrence',
          field: 'concurrent_availability_rate',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Disponibilité des actions réalisées',
          field: 'actions_done_rate_strict',
          type: 'number',
          filterable: true,
        },
      ],
      disponibilities_data: [],
      commerciaux: [],
      commercial: null,
      regions: [],
      region: null,
      region1: null,
      visitesCommercialJourChartData: [],
      visitesCommercialChartData: [],
      stateData: [],
      componentKey: 0,
      componentKeyS: 0,
      componentVCJCKey: 0,
      dateDebut: '',
      dateFin: '',
      dateFinStat: '',
      dateDebutStat: '',
      statChartData: [],
      statChartData2: [],
      visitesCommercials: [],
      dateDebutVisite: '',
      dateFinVisite: '',
      chartDataStackedColumnChart: [],
      series: [
        {
          valueField: 'afridia_availability_rate',
          seriesName: 'Jus Afridia',
        },
        {
          valueField: 'concurrent_availability_rate',
          seriesName: 'Jus Concurrent',
        },
        {
          valueField: 'actions_done_rate',
          seriesName: 'Actions Done',
        },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.dateDebut = this.getFirstDayOfCurrentMonth()
    this.dateFin = this.getCurrentDate()
    this.dateDebutStat = this.getFirstDayOfCurrentMonth()
    this.dateFinStat = this.getCurrentDate()
    this.dateDebutVisite = this.getBeforeYesterday()
    this.dateFinVisite = this.getCurrentDate()
    this.getAvailabilityRatesData()
    this.getRegions()
    this.getVisitesCommercial()
    this.getCommercials()
  },
  methods: {
    exportExcel() {
      const header = this.columns.map(column => column.label)
      const data = [header].concat(this.stateData.map(row => this.columns.map(column => row[column.field])))

      // Créer un workbook avec une feuille
      const ws = XLSX.utils.aoa_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Export')

      // Générer le fichier et le télécharger
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array',
      })
      const blob = new Blob([wbout], { type: 'application/octet-stream' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${generateConcatenatedWord('Statistique_par_commercial')}.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    exportCSVS() {
      const header = this.columns.map(column => column.label)
      const data = this.stateData.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(','))
        .join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${generateConcatenatedWord('Statistique_par_commercial')}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    exportCSVS2() {
      const header = this.columns2.map(column => column.label)
      const data = this.disponibilities_data.map(row => this.columns2.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(','))
        .join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${generateConcatenatedWord('Statistique_par_commercial')}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    getAvailabilityRatesData() {
      this.show1 = true
      this.$http.get(`${API_URL}get-availability-rates/?date_debut=${this.dateDebut}&date_fin=${this.dateFin}`)
        .then(response => {
          this.chartDataStackedColumnChart = response.data
          this.disponibilities_data = response.data
          this.componentKey += 1
          this.show1 = false
        })
        .catch(error => {
          this.show1 = false
          console.log(error)
        })
    },
    getStats() {
      this.show = true
      this.$http.get(`${API_URL}get-stats-per-commercial/${this.commercial.uuid}?start_date=${this.dateDebutStat}&end_date=${this.dateFinStat}`)
        .then(response => {
          this.stateData = [response.data]
          // this.statChartData2 = response.data.action
          this.getVisitesCommercialJour()
          this.componentKeyS += 1
          this.show = false
        })
        .catch(error => {
          this.show = false
          console.log(error)
        })
    },
    getFirstDayOfCurrentMonth() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (1 + date.getMonth()).toString()
        .padStart(2, '0')
      return `${year}-${month}-01`
    },
    getCurrentDate() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (1 + date.getMonth()).toString()
        .padStart(2, '0')
      const day = date.getDate()
        .toString()
        .padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    getVisitesCommercialJour() {
      let uuid = ''
      if (this.commercial) {
        uuid = `uuid=${this.commercial.uuid}`
      }
      this.$http.get(`${API_URL}visites-commercial-jour/?date_debut=${this.dateDebutStat}&date_fin=${this.dateFinStat}&${uuid}`)
        .then(response => {
          this.visitesCommercialJourChartData = response.data
          this.componentVCJCKey += 1
        })
        .catch(error => {
          console.log(error)
        })
    },
    getVisitesCommercial() {
      this.show2 = true
      let regionId = 1
      if (this.region) {
        regionId = this.region.id
      }
      this.$http.get(`${API_URL}get-visits-per-commercial/?start_date=${this.dateDebutVisite}&end_date=${this.dateFinVisite}&region_id=${regionId}`)
        .then(response => {
          this.show2 = false
          this.visitesCommercials = response.data
          this.componentSimplieColumnChartKey += 1
        })
        .catch(error => {
          this.show2 = false
          console.log(error)
        })
    },
    getBeforeYesterday() {
      const date = new Date()
      date.setDate(date.getDate() - 2)
      const year = date.getFullYear()
      const month = (1 + date.getMonth()).toString()
        .padStart(2, '0')
      const day = date.getDate()
        .toString()
        .padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    getCommercials() {
      this.$http.get(`${API_URL}commercials/?best_commercial=true`)
        .then(response => {
          this.commerciaux = response.data
          // eslint-disable-next-line prefer-destructuring
          this.commercial = this.commerciaux[0]
          this.getStats()
          console.log(this.commerciaux)
        })
        .catch(error => {
          console.log(error)
        })
    },
    getRegions() {
      this.$http.get(`${API_URL}regions/`)
        .then(response => {
          this.regions = response.data
          // eslint-disable-next-line prefer-destructuring
          this.region = this.regions[0]
          // eslint-disable-next-line prefer-destructuring
          this.region1 = this.regions[0]
          this.getVisitesCommercial()
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
